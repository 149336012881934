const trackonMapWhite = '#FFFFFF';
const trackonMapPurple = '#3488AC';
const trackonMapGreen = '#4CAF50';
const trackonMapRed = '#CC2222';
const trackonMapGray = '#888888';

export default {
  common: {
    purple: trackonMapPurple,
    green: trackonMapGreen,
    red: trackonMapRed,
    gray: trackonMapGray,
  },
  primary: {
    main: trackonMapPurple,
  },
  secondary: {
    main: trackonMapGreen,
    contrastText: trackonMapWhite,
  },
  colors: {
    red: {
      color: trackonMapRed,
    },
    green: {
      color: trackonMapGreen,
    },
    gray: {
      color: trackonMapGray,
    },
  },
};
