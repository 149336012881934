import React from 'react';
import { Grid, makeStyles, Paper, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundImage: "url('/images/primarybg.png')",
    backgroundColor: 'rgba(255, 255, 255, .9)',
    backgroundRepeat: 'repeat',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    width: '100%',
  },
  banner: {
    height: '100%',
    width: '100%',
  },
}));

const StartPage = ({ children }) => {
  const classes = useStyles();
  // const theme = useTheme();

  return (
    <main className={classes.root}>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid
          className={classes.container}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6} className="qrCodeWrapper">
            <img className={classes.qrCode} src="/images/qr-code.png" alt="Track on Map" />
            <p>Scan QR-Code to download our mobile apps.</p>
          </Grid>
          <Grid item direction="row" xs={12} md={6}>
            <Paper className={classes.paper}>
              <form className={classes.form}>
                { children }
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default StartPage;
